/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { useAuthInfo } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
// Layouts
import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import MainLayout from 'layouts/MainLayout/MainLayout'
import CaseLayout from 'layouts/CaseLayout/CaseLayout'
// Auth Pages
import LoginPage from 'pages/AuthPages/LoginPage/LoginPage'
import ExternalLoginPage from 'pages/AuthPages/ExternalLoginPage/ExternalLoginPage'
// Main Pages
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage'
import CaseListPage from 'pages/CaseListPage/CaseListPage'
import CaseWorkflowPage from 'pages/CaseWorkflowPage/CaseWorkflowPage'
import MessagesPage from 'pages/MessagesPage/MessagesPage'
import MessagesContainerPage from 'pages/MessagesContainerPage/MessagesContainerPage'
import DocumentsPage from 'pages/DocumentsPage/DocumentsPage'
import QuotePage from 'pages/QuotePage/QuotePage'
import NewMissionPage from 'pages/NewMissionPage/NewMissionPage'
import InvoicePage from 'pages/InvoicePage/InvoicePage'

/* Component declaration ---------------------------------------------------- */
interface RouterProps {}

const Router: React.FC<RouterProps> = () => {
  const authInfo = useAuthInfo()

  console.log('[DEBUG] authInfo :', authInfo)

  return (
    <Routes>
      <Route
        path="/"
        element={
          authInfo !== null ?
            <MainLayout /> :
            <Navigate
              to="/connexion"
              replace
            />
        }
      >
        <Route path="dossiers">
          <Route
            index
            element={<CaseListPage />}
          />
          <Route
            path=":caseId"
            element={<CaseLayout />}
          >
            <Route
              index
              element={<CaseWorkflowPage />}
            />
            {
              !authInfo?.lectureSeule &&
                <>
                  <Route
                    path="devis"
                    element={<QuotePage />}
                  />
                  <Route
                    path="facture"
                    element={<InvoicePage />}
                  />
                </>
            }
          </Route>
        </Route>
        {
          !authInfo?.lectureSeule &&
            <Route
              path="missionnement"
              element={<NewMissionPage />}
            />
        }
        <Route
          path="evenements/:caseId"
          element={<CaseLayout />}
        >
          <Route
            path=""
            element={<div />}
          />
        </Route>
        <Route
          path="messagerie"
          element={<MessagesContainerPage />}
        />
        <Route
          path="messagerie/:caseId"
          element={<CaseLayout />}
        >
          <Route
            path=""
            element={<MessagesPage />}
          />
        </Route>
        <Route
          path="documents/:caseId"
          element={<CaseLayout />}
        >
          <Route
            path=""
            element={<DocumentsPage />}
          />
        </Route>
        <Route
          index
          element={
            <Navigate
              to="/dossiers"
              replace
            />
          }
        />
        <Route
          path="*"
          element={<NotFoundPage />}
        />
      </Route>
      <Route
        element={
          authInfo === null ?
            <AuthLayout /> :
            <Navigate
              to="/"
              replace
            />
        }
      >
        <Route
          path="/connexion"
          element={<LoginPage />}
        />
      </Route>
      <Route element={<AuthLayout />}>
        <Route
          path="/connexion-automatique"
          element={<ExternalLoginPage />}
        />
      </Route>
      <Route
        path="*"
        element={<NotFoundPage />}
      />
    </Routes>
  )
}

export default Router
