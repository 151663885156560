/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  downloadFile,
  getCleanFilename,
  printFile,
} from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import {
  PrintRounded,
  DownloadRounded,
  VisibilityOutlined,
} from '@mui/icons-material'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'

/* Type imports ------------------------------------------------------------- */
import type { ElementMediatheque } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const ButtonsContainer = styled.div`
  display: flex;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
    gap: 5px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface DocumentsActionButtonsProps {
  file: ElementMediatheque;
}

const DocumentsActionButtons: React.FC<DocumentsActionButtonsProps> = ({ file }) => {

  const onFileDonwloadClick = () => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    downloadFile(getCleanFilename(file.libelle, file.fileName || ''), file.url)
  }

  const onFileVisualizeClick = () => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    window.open(file.url || '', '_blank', 'noreferrer')
  }

  const onFilePrintClick = () => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    printFile(file.url)
  }

  return (
    <ButtonsContainer>
      <CustomIconButton
        onClick={onFileDonwloadClick()}
        Icon={DownloadRounded}
        variant="outlined"
        disabled={!file.url}
      />
      <CustomIconButton
        onClick={onFilePrintClick()}
        Icon={PrintRounded}
        variant="outlined"
        disabled={!file.url}
      />
      <CustomIconButton
        onClick={onFileVisualizeClick()}
        Icon={VisibilityOutlined}
        variant="outlined"
        disabled={!file.url}
      />
    </ButtonsContainer>
  )
}

export default DocumentsActionButtons
